<template>
	<article class="grille-wrap">
		<div class="grilleItem">
			<figure class="previewTiny">
				<img :src="previewSrc" />
			</figure>
		</div>
		<form class="grille">
			<h3>Design</h3>
			<div class="grilleItem">
				<image-autocomplete
					v-model="grille.GrilleOption.Application"
					type="application"
					:is-error="applicationIsError"
					:mobile="isMobile"
					@sbInput="OnInput">
					<template v-slot:error>Application Required</template>
				</image-autocomplete>
			</div>
			<div class="grilleItem">
				<image-autocomplete
					v-model="grille.GrilleStyle"
					type="style"
					:limitingIds="limitingIds"
					:is-error="styleIsError"
					:mobile="isMobile"
					@sbInput="OnInput">
					<template v-slot:error>Style Required</template>
				</image-autocomplete>
			</div>
			<div class="grilleItem" v-show="!patternDisabled">
				<image-autocomplete
					v-model="grille.GrillePatterns"
					type="pattern"
					:limitingIds="limitingIds"
					:is-error="patternIsError"
					:mobile="isMobile"
					@sbInput="OnInput"
					@disabled="PatternDisabled">
					<template v-slot:error>Pattern Required</template>
				</image-autocomplete>
			</div>
			<h3>Material</h3>
			<div class="grilleItem" v-show="!materialDisabled">
				<image-autocomplete
					v-model="grille.GrilleMaterial"
					type="material"
					:limitingIds="limitingIds"
					:is-error="materialIsError"
					:mobile="isMobile"
					@sbInput="OnInput"
					@disabled="MaterialDisabled">
					<template v-slot:error>Material Required</template>
				</image-autocomplete>
			</div>
			<div class="grilleItem" v-show="!finishDisabled">
				<image-autocomplete
					v-model="grille.GrilleFinish"
					type="finish"
					:limitingIds="limitingIds"
					:is-error="finishIsError"
					:mobile="isMobile"
					@sbInput="OnInput"
					@disabled="FinishDisabled">
					<template v-slot:error>Finish Required</template>
				</image-autocomplete>
			</div>
			<div class="grilleItem" v-show="!frameDisabled">
				<image-autocomplete
					v-model="grille.GrilleFrame"
					type="frame"
					:limitingIds="limitingIds"
					:is-error="frameIsError"
					:mobile="isMobile"
					@sbInput="OnInput"
					@disabled="FrameDisabled">
					<template v-slot:error>Frame Required</template>
				</image-autocomplete>
			</div>
			<div class="grilleItem" v-show="customThicknessDisabled && !thicknessDisabled">
				<image-autocomplete
					v-model="grille.GrilleThickness"
					type="thickness"
					:limitingIds="limitingIds"
					:is-error="thicknessIsError"
					:mobile="isMobile"
					@sbInput="OnInput"
					@disabled="ThicknessDisabled">
					<template v-slot:error>Thickness Required</template>
				</image-autocomplete>
			</div>
			<div class="grilleItem" v-if="!customThicknessDisabled">
				<div class="inputWrap">
					<label>Custom Thickness</label>
					<o-input
						v-model="grille.GrilleOption.CustomThickness"
						type="text"
						:class="customThicknessIsError ? 'inputError' : ''"
						@input="OnInput"></o-input>
				</div>
			</div>
			<h3>Size (in Inches)</h3>
			<div class="grilleItem sizes">
				<template v-if="!hasStockOptions">
					<div class="inputWrap">
						<template v-if="!isLinear">
							<label>Measurement Type</label>
							<o-select v-model="measurementTypeId" expanded @input="OnInput">
								<option :value="1">Opening</option>
								<option :value="2">Overall</option>
							</o-select>
							<fast-tooltip
								src="measurement-types.jpg"
								position="top"
								class="ftt_oruga"
								max-width="18em"
								style="left: 10.2em">
								<o-icon icon="question-circle" class="mx-2 my-2"></o-icon>
							</fast-tooltip>
						</template>
						<template v-else>
							<b>
								(Opening measurement
								<fast-tooltip
									src="measurement-types.jpg"
									position="top"
									class="ftt_oruga"
									max-width="18em"
									style="display: inline-block; position: unset">
									<o-icon icon="question-circle" class="mx-2 my-2"></o-icon>
								</fast-tooltip>
								)
							</b>
						</template>
					</div>
					<div class="sizeInput" v-for="size in grille.GrilleSizes" :key="size.Id">
						<div class="inputWrap inches">
							<label>Height</label>
							<o-input
								v-model.number="size.Height"
								type="number"
								:min="0"
								:max="999999"
								step="any"
								:class="HeightIsError(size) ? 'inputError' : ''"
								@input="OnInput"></o-input>
							<o-tooltip appendToBody position="right" variant="info">
								<template v-slot:content>
									<div>Enter in decimal format.</div>
								</template>
								<o-icon icon="question-circle" class="mx-2 my-2"></o-icon>
							</o-tooltip>
						</div>
						<div class="inputWrap inches">
							<label>Length</label>
							<o-input
								v-model.number="size.Length"
								type="number"
								:min="0"
								:max="999999"
								step="any"
								:class="LengthIsError(size) ? 'inputError' : ''"
								@input="OnInput"></o-input>
						</div>
						<div class="inputWrap sbQuantity">
							<label>Qty.</label>
							<o-input
								v-model.number="size.Quantity"
								type="number"
								min="1"
								:class="QtyIsError(size) ? 'inputError' : ''"
								@input="OnInput"></o-input>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="sizeInput inputWrap" v-for="(size, index) in grille.GrilleSizes" :key="index">
						<stock-size-selector
							v-model="grille.GrilleSizes[index]"
							:grille="grille"
							@input="OnInput"></stock-size-selector>
						<fast-tooltip
							src="measurement-types.jpg"
							position="top"
							class="ftt_oruga"
							max-width="18em"
							style="left: 6.7em">
							<o-icon icon="question-circle" class="mx-2 my-2"></o-icon>
						</fast-tooltip>
					</div>
				</template>
				<o-icon
					class="delRowBtn"
					icon="minus"
					pack="fas"
					clickable
					v-if="grille.GrilleSizes.length > 1"
					@click="PopSize"></o-icon>
				<o-icon class="newRowBtn" icon="plus" pack="fas" clickable @click="NewSize"></o-icon>
			</div>
			<h3>Options</h3>
			<div v-if="typeId === 1" class="checkboxGrid">
				<o-field>
					<o-checkbox v-model="grille.GrilleOption.HasDoor" @input="OnInput">
						Access door
					</o-checkbox>
					<template v-if="grille.GrilleOption.HasDoor">
						<span style="display: flex; flex-direction: column; align-items: flex-start">
							<o-radio v-model="grille.GrilleOption.DoorOnRight" :native-value="false">
								On the left
							</o-radio>
							<o-radio v-model="grille.GrilleOption.DoorOnRight" :native-value="true">
								On the right
							</o-radio>
						</span>
					</template>
				</o-field>
				<o-field>
					<o-checkbox v-model="grille.GrilleOption.HasRemovableCore" @input="OnInput">
						Removable core
					</o-checkbox>
				</o-field>
			</div>
			<template v-if="typeId === 2">
				<o-checkbox v-model="grille.GrilleOption.HasMountingHoles" size="large" @input="OnInput">
					<span>Mounting holes?</span>
					<o-tooltip appendToBody position="right" variant="info" style="z-index: 100">
						<template v-slot:content>
							<div>
								Provide either a surface or flush mount
								<br />
								technique for fastening your grille.
								<br />
								Our specialist will work with you to determine the appropriate
								<br />
								screw size and quantity of holes for your application.
							</div>
						</template>
						<o-icon icon="question-circle" class="mx-2 my-2"></o-icon>
					</o-tooltip>
				</o-checkbox>
				<o-checkbox v-model="grille.GrilleOption.HasJBeadFrame" size="large" @input="OnInput">
					J-Bead style frame?
				</o-checkbox>
			</template>
			<div class="inputWrap">
				<label>Special requirements</label>
				<o-input
					v-model="grille.GrilleOption.SpecialRequirements"
					type="textarea"
					maxlength="185"
					placeholder="Optional: Special instructions for this grille"
					rows="4"
					class="sbTextArea"
					style="height: 4em"
					@input="OnInput"></o-input>
				<!--using inline styling was the only way I could find to set a default height --Camron -->
			</div>
		</form>
		<div class="mx-2" v-if="errors.length > 0">
			<div class="is-danger" v-for="(err, i) in errors" :key="i">
				{{ err }}
			</div>
		</div>
		<div class="grilleButtons">
			<div>
				<o-tooltip label="Copy" position="top" variant="primary">
					<o-button icon-left="copy" @click="Duplicate">Duplicate</o-button>
				</o-tooltip>
				<o-tooltip label="Delete" position="top" variant="primary">
					<o-button icon-left="trash" @click="Delete">Delete</o-button>
				</o-tooltip>
			</div>
			<!--
    <div class="inputWrap" v-for="(size, index) in duplicated" :key="size.Id">
        <o-tooltip label="Copy" position="top" variant="primary">
            <o-button icon-left="copy" @click="DuplicateSize(index)"></o-button>
        </o-tooltip>
        <o-tooltip label="Delete" position="top" variant="primary">
            <o-button icon-left="trash" @click="DeleteSize(index)"></o-button>
        </o-tooltip>
    </div>-->
		</div>
	</article>
</template>
<script>
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	import { ImageAutocomplete, StockSizeSelector } from "@/components/schedule";
	import { fastTooltip } from "@/components";
	//import { BorderLabelInput } from "@/components"
	import useValidate from "@vuelidate/core";
	import { required, email } from "@vuelidate/validators";
	import { GrilleType } from "@/enums";

	export default {
		props: {
			modelValue: {
				type: [Object, Array],
				default: () => {
					return {};
				}
			}
		},
		components: {
			"image-autocomplete": ImageAutocomplete,
			"stock-size-selector": StockSizeSelector,
			"fast-tooltip": fastTooltip
			//"border-label-input": BorderLabelInput
		},
		emits: ["update:modelValue", "duplicate", "delete", "sbInput"],
		setup(props, context) {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $http = inject("$http");
			const $httpGetCached = inject("$httpGetCached");
			const $success = inject("$success");
			const $error = inject("$error");
			const $mobileCheck = inject("$mobileCheck");

			const isMobile = $mobileCheck();
			const api = ref(process.env.VUE_APP_API);
			const loading = ref(true);
			const saving = ref(false);
			const specProcessing = ref(false);
			const grille = ref(props.modelValue);

			const typeId = computed(() => {
				return grille.value.TypeId;
			});
			const applicationId = computed(() => {
				return grille.value.GrilleOption?.Application?.Id || 0;
			});
			const styleId = computed(() => {
				return grille.value.GrilleStyle?.Id || 0;
			});
			const materialId = computed(() => {
				return grille.value.GrilleMaterial?.Id || 0;
			});
			const limitingIds = computed(() => {
				return {
					application: applicationId.value,
					type: typeId.value,
					style: styleId.value,
					material: materialId.value
				};
			});

			const isLinear = computed(() => {
				return typeId.value === GrilleType.Linear;
			});
			const isPerforated = computed(() => {
				return typeId.value === GrilleType.Perforated;
			});
			const isJBead = computed(() => {
				return typeId.value === GrilleType.JBead;
			});

			const isCustomFinish = computed(() => {
				return !!grille.value.GrilleFinish.Name.match(/custom/i);
			});

			const v = useValidate(
				{
					grille: {
						TypeId: {
							required
						},
						GrilleStyle: {
							Id: {
								required
							}
						},
						GrillePatterns: {
							IsRequired: (value) => {
								return (isPerforated.value && value.Name) || !isPerforated.value;
							}
						},
						GrilleMaterial: {
							Id: {
								required
							}
						},
						GrilleFinish: {
							Id: {
								required
							}
						},
						GrilleFrame: {
							IsRequired: (value) => {
								return (isLinear.value && value.Id > 0) || !isLinear.value;
							}
						},
						GrilleThickness: {
							IsRequired: (value) => {
								return (
									(isPerforated.value && value.Id > 0) || !isPerforated.value || isCustomFinish.value
								);
							}
						},
						GrilleSizes: {
							HasSize: (value) => {
								return value.length > 0;
							},
							HasHeights: (value) => {
								return value.every((s) => s.Height && s.Height > 0);
							},
							HasLengths: (value) => {
								return value.every((s) => s.Length && s.Length > 0);
							},
							HasQuantities: (value) => {
								return value.every((s) => s.Quantity && s.Quantity > 0);
							}
						},
						GrilleOption: {
							Application: {
								IsRequired: (value) => {
									return value.Id && value.Id > 0;
								}
							},
							CustomThickness: {
								IsRequiredIfCustom: (value) => {
									if (isCustomFinish.value) {
										return !!value;
									}
									return true;
								}
							}
						}
					}
				},
				{ grille }
			);

			const measurementTypeId = ref(1);

			const patternDisabled = ref(true);
			const PatternDisabled = (e) => {
				patternDisabled.value = e;
			};
			const materialDisabled = ref(true);
			const MaterialDisabled = (e) => {
				materialDisabled.value = e;
			};
			const finishDisabled = ref(true);
			const FinishDisabled = (e) => {
				finishDisabled.value = e;
			};
			const frameDisabled = ref(true);
			const FrameDisabled = (e) => {
				frameDisabled.value = e;
			};
			const thicknessDisabled = ref(true);
			const ThicknessDisabled = (e) => {
				thicknessDisabled.value = e;
			};
			const customThicknessDisabled = computed(() => {
				return !isCustomFinish.value;
			});

			const HeightIsError = (size) => {
				return !(size.Height > 0) && v.value.grille.$dirty;
			};
			const LengthIsError = (size) => {
				return !(size.Length > 0) && v.value.grille.$dirty;
			};
			const QtyIsError = (size) => {
				return !(size.Quantity > 0) && v.value.grille.$dirty;
			};
			const typeIsError = computed(() => {
				return v.value.grille.TypeId.$invalid && v.value.grille.$dirty;
			});
			const styleIsError = computed(() => {
				return v.value.grille.GrilleStyle.Id.$invalid && v.value.grille.$dirty;
			});
			const patternIsError = computed(() => {
				return v.value.grille.GrillePatterns.$invalid && v.value.grille.$dirty;
			});
			const materialIsError = computed(() => {
				return v.value.grille.GrilleMaterial.Id.$invalid && v.value.grille.$dirty;
			});
			const finishIsError = computed(() => {
				return v.value.grille.GrilleFinish?.Id.$invalid && v.value.grille.$dirty;
			});
			const frameIsError = computed(() => {
				return v.value.grille.GrilleFrame.$invalid && v.value.grille.$dirty;
			});
			const thicknessIsError = computed(() => {
				return (
					v.value.grille.GrilleThickness.$invalid && v.value.grille.$dirty && !isCustomFinish.value
				);
			});
			const customThicknessIsError = computed(() => {
				return v.value.grille.GrilleOption.CustomThickness.$invalid && v.value.grille.$dirty;
			});
			const applicationIsError = computed(() => {
				return v.value.grille.GrilleOption.Application.$invalid && v.value.grille.$dirty;
			});

			const errors = computed(() => {
				const list = [];
				if (typeIsError.value) {
					list.push("Type is required.");
				}
				if (applicationIsError.value) {
					list.push("Application is required.");
				}
				if (styleIsError.value) {
					list.push("Style is required.");
				}
				if (patternIsError.value) {
					list.push("Pattern is required.");
				}
				if (materialIsError.value) {
					list.push("Material is required.");
				}
				if (finishIsError.value) {
					list.push("Finish is required.");
				}
				if (frameIsError.value) {
					list.push("Frame is required.");
				}
				if (thicknessIsError.value) {
					list.push("Thickness is required.");
				}
				if (isCustomFinish.value && customThicknessIsError.value) {
					list.push("Custom thickness is required.");
				}
				if (v.value.grille.GrilleSizes.$invalid && v.value.grille.$dirty) {
					list.push("Sizes are invalid.");
				}
				return list;
			});

			if (Object.keys(grille).length === 0) {
				grille.value.Id = 0;
				grille.value.Type = {};
				grille.value.GrilleFrame = {};
				grille.value.GrilleStyle = {
					Id: 0,
					Name: "",
					Src: ""
				};
				grille.value.GrilleMaterial = {};
				grille.value.GrilleFinish = {};
				grille.value.GrillePatterns = {
					GrilleId: 0
				};
				grille.value.GrilleSizes = [
					{
						MeasurementTypeId: measurementTypeId.value
					}
				];
				grille.value.GrilleThickness = {};
				grille.value.GrilleOption = {
					GrilleId: 0
				};
			}

			const previewSrc = computed(() => {
				if (!grille.value.GrilleStyle.Id || grille.value.GrilleStyle.Id === 0) {
					return "";
				} else {
					return api.value + "api/image/" + grille.value.GrilleStyle.Src;
				}
			});

			const duplicated = computed(() => {
				return grille.value.GrilleSizes.slice(1);
			});

			const filter = ref({
				typeId: null,
				styleId: null,
				materialId: null,
				finish: null,
				frameId: null
			});

			const stockSizes = ref([]);
			const GetStockSizes = async () => {
				if (grille.value.GrilleStyle.Id) {
					loading.value = true;
					try {
						var response = await $httpGetCached(
							"/api/grilleStyleType/" + grille.value.GrilleStyle.Id + "/stock/sizes"
						);
						stockSizes.value = response.data;
						for (const size of stockSizes.value) {
							size.MeasurementTypeId = measurementTypeId.value;
						}
					} catch (err) {
						$error("Failed to get stock sizes.");
					}
					loading.value = false;
				}
			};
			const hasStockOptions = computed(() => {
				return stockSizes.value.length > 0;
			});

			const TotalAmount = (row) => {
				let total = 0;
				for (const size of row.GrilleSizes) {
					if (size.Quantity) {
						total += parseInt(size.Quantity);
					}
				}
				return total;
			};

			const Delete = () => {
				context.emit("delete", grille.value);
			};

			const Duplicate = () => {
				context.emit("duplicate", grille);
			};

			const NewSize = () => {
				if (isJBead.value) {
					grille.value.GrilleSizes.push({
						Id: null,
						Length: null,
						Height: null,
						MeasurementTypeId: measurementTypeId.value,
						Quantity: 1
					});
				} else {
					grille.value.GrilleSizes.push({
						Length: null,
						Height: null,
						MeasurementTypeId: measurementTypeId.value,
						Quantity: 1
					});
				}
			};

			const PopSize = () => {
				grille.value.GrilleSizes.pop();
			};

			const OnInput = (e) => {
				if (e?.GrilleTypeId) {
					grille.value.TypeId = e.GrilleTypeId;
					if (grille.value.TypeId === GrilleType.JBead) {
						grille.value.GrilleSizes = [{}];
					}
				}

				context.emit("update:modelValue", grille.value);
				context.emit("sbInput", e);
			};

			const SyncSizeTypes = () => {
				if (!loading.value)
					for (const size of grille.value.GrilleSizes) {
						size.MeasurementTypeId = measurementTypeId.value;
					}
			};

			const TryInit = () => {
				// Place anything that needs a hard initialization.
				if (grille.value.GrilleSizes && grille.value.GrilleSizes.length > 0) {
					measurementTypeId.value = grille.value.GrilleSizes[0].MeasurementTypeId;
				}
			};

			onMounted(async () => {
				const val = grille.value.GrilleSizes[0]?.MeasurementTypeId;
				if (val || val === 0) {
					SyncSizeTypes();
				} else {
					grille.value.GrilleSizes[0] = {
						MeasurementTypeId: measurementTypeId.value,
						Quantity: 1
					};
				}
				GetStockSizes();
				TryInit();
				grille.value.IsValid = computed(() => {
					return !v.value.grille.$invalid;
				});
				grille.value.IsDirty = computed(() => {
					return v.value.grille.$dirty;
				});
				grille.value.Touch = () => {
					v.value.grille.$touch();
				};
				loading.value = false;
			});

			watch(
				() => grille.value.GrilleStyle,
				(n, o) => {
					if (n && n !== o) {
						GetStockSizes();
					}
				},
				{ deep: true }
			);

			watch(
				() => measurementTypeId.value,
				(n, o) => {
					if (n !== o) {
						SyncSizeTypes();
					}
				}
			);

			return {
				isMobile,
				api,
				loading,
				saving,
				specProcessing,
				measurementTypeId,
				patternDisabled,
				PatternDisabled,
				materialDisabled,
				MaterialDisabled,
				finishDisabled,
				FinishDisabled,
				frameDisabled,
				FrameDisabled,
				thicknessDisabled,
				ThicknessDisabled,
				customThicknessDisabled,
				HeightIsError,
				LengthIsError,
				QtyIsError,
				typeIsError,
				styleIsError,
				patternIsError,
				materialIsError,
				finishIsError,
				frameIsError,
				thicknessIsError,
				customThicknessIsError,
				applicationIsError,
				errors,
				typeId,
				styleId,
				materialId,
				limitingIds,
				grille,
				v,
				previewSrc,
				duplicated,
				filter,
				isPerforated,
				isLinear,
				isCustomFinish,
				Delete,
				Duplicate,
				NewSize,
				PopSize,
				OnInput,
				TotalAmount,
				stockSizes,
				hasStockOptions
			};
		}
	};
</script>
<style lang="scss">
	@import "@/assets/css/globalVars.scss";
	@import "@/assets/css/scheduleBuilderVars.scss";

	h3 {
		margin-top: 1em;
		font-weight: bold;
		font-style: italic;
		display: none;
	}

	input.inches::after {
		content: "in.";
		position: absolute;
		top: 2em;
		right: 3em;
	}

	div.grilleButtons {
		position: relative;
		z-index: 10;
		padding: $radius-large;
		background-color: $article-background;

		& > div {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			gap: 0.4em;
		}
	}

	div.grilleItem {
		position: relative;

		&.sizes {
			margin-bottom: 1.5em;
		}

		& .delRowBtn {
			$plusColor: $primary;
			$lightColor: lighten($row-divider-color, 20%);

			position: absolute;
			bottom: calc(-#{$grid-gap-y} + 0.2em);
			right: 0;
			width: 1rem;
			height: 1rem;
			//background-color: $info;
			background-color: transparent;
			color: $info;

			& svg {
				//filter: drop-shadow(-1px -1px 0.5px lighten($info, 30%));
				font-size: 0.8rem;
			}

			&:hover {
				color: white;
				background-color: $danger;
				//background-color: darken($info, 20%);

				&::before {
					$msgWidth: 5.9em;
					content: "Remove size";
					position: absolute;
					left: -$msgWidth;
					display: inline-block;
					width: $msgWidth;
					font-size: 0.8em;
					color: $danger;
				}
			}
		}

		& .newRowBtn {
			$plusColor: $primary;
			$lightColor: lighten($row-divider-color, 20%);

			position: absolute;
			bottom: calc(-#{$grid-gap-y} + 0.2em);
			left: 0;
			width: 1rem;
			height: 1rem;
			//background-color: $info;
			background-color: transparent;
			color: $info;

			& svg {
				//filter: drop-shadow(-1px -1px 0.5px lighten($info, 30%));
				font-size: 0.8rem;
			}

			&:hover {
				color: white;
				background-color: $success;
				//background-color: darken($info, 20%);

				&::before {
					$msgWidth: 4em;
					content: "Add size";
					position: absolute;
					right: -$msgWidth;
					display: inline-block;
					width: $msgWidth;
					font-size: 0.8em;
					text-align: right;
					color: $success;
				}
			}
		}
	}

	div.checkboxGrid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		margin-top: -#{$inner-gap-y/2};
		& .field.has-addons {
			flex-direction: column;

			& .radio {
				margin: 0;
			}
		}
	}

	figure.previewTiny {
		$img-height: 5rem;

		position: relative;
		z-index: 10;
		padding: 0;
		margin: 0;
		//box-shadow: 0 0em 0.6em -0.1em #000000f7;
		width: 100%;
		//background-color: $article-background;
		text-align: center;
		padding: $radius-large 0;
		font-size: 0;

		& img {
			display: inline-block;
			width: auto;
			height: $img-height;
		}
	}

	.inputWrap {
		position: relative;

		&.sbQuantity {
			width: 9em;
		}

		&.inches::after {
			content: "inches";
			position: absolute;
			top: 2.2em;
			right: 0.7em;
			font-size: 0.8em;
			background-color: white;
		}

		& .ftt_oruga {
			padding: 0.2em;
		}

		& .b-tooltip,
		& .ftt_oruga {
			position: absolute;
			top: -0.8em;
			left: 4.5em; //TODO: make horizontal positioning automatic
			margin-left: 4px;
			font-size: 0.75em;
			line-height: 0;
			background-color: #fff;

			&:not(.ftt_oruga) .icon {
				width: 0;
				height: 0;
			}
		}
	}

	.dropdown-menu {
		z-index: 101;
	}

	.inputError {
		@include o-input-error;
	}

	.invisible {
		visibility: hidden;
	}
</style>
