<template>
    <div class="modal-card limit-modal-size" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                {{title}}
            </p>
            <o-icon type="button" icon="times" @click="$emit('close')" clickable></o-icon>
        </header>
        <section class="modal-card-body">
            <h2>Input Prices</h2> <br />
            <div v-for="grille in quote.Grilles" :key="grille">
                <div v-for="(size, index) in grille.GrilleSizes" :key="index" class="columns">
                    <div class="column is-9">
                        {{grille.GrilleStyle.Style.Name}}, {{grille.GrilleMaterial.Material.Name}},
                        {{(grille.TypeId == 2 ||  grille.TypeId == 5 ? "" : grille.GrilleFrame?.Frame?.Name + ",")}}
                        {{grille.GrilleFinish.Finish.Name}},
                        {{size.Length +"x"+ size.Height + ","}}
                        {{grille.GrillePatterns[0]?.Pattern?.Name == nulls ? "" : grille.GrillePatterns[0]?.Pattern?.Name + ","}}
                        {{(grille.TypeId !== 2 ? "" : grille?.GrilleThickness?.Thickness?.Name + ",")}}
                        Qty: {{size.Quantity}}
                        Price: ${{size.Price}}
                        <!-- , {{grille.GrilleSizes.Sum(x => x.Quantity)}} -->
                    </div>
                    <div class="column">
                        <o-input size="medium" type="number" placeholder="$ 0.0" v-model="size.Price" />
                    </div>
                </div>
            </div>

            <div class="row columns">
                <div class="column is-9">
                    Shipping
                </div>
                <div class="column">
                    <o-input size="medium" type="number" placeholder="$0.0" v-model="quote.Shipping" />
                </div>
            </div>
            <div class="row columns">
                <div class="column is-9">
                    Total
                </div>
                <div class="column">
                    <span v-if="quote.Grilles">
                        ${{quote.Grilles.reduce((acc, x) => Number(x.GrilleSizes.reduce((acc2, x2) => Number(x2.Price) + acc2, 0)) + acc, 0) + Number(quote.Shipping)}}
                    </span>
                </div>
            </div>
        </section> 
        <footer class="modal-card-foot is-justify-content-space-between">
            <div>
                <o-button type="button" variant="light" :disabled="sending" @click="Cancel">Cancel</o-button>
                <o-button type="button" variant="primary" :disabled="sending || (submitAttempted && !allIsValid)" @click="SendQuote">Send Quote</o-button>
                <div v-if="sending" class="loading-wrap"><loading></loading>&nbsp;Sending...</div>
            </div>
            <div class="is-danger">
                <div v-if="sizesIsError && submitAttempted">
                    {{ "One or more of the grille prices is invalid." }}
                </div>
                <div v-if="shippingIsError && submitAttempted">
                    {{ "Invalid shipping cost." }}
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
    import { ScheduleRow, ScheduleGrille } from "@/components/schedule";
    import { helpers, required, numeric } from "@vuelidate/validators";
    import { loading } from "@/components/";
    import useValidate from "@vuelidate/core";

    import {
        getCurrentInstance,
        ref,
        computed,
        watch,
        onMounted,
        inject,
    } from "vue";
    export default {
        props: {
            title: {
                type: String,
                default: "Contact Information",
            },
            description: {
                type: String,
                default: "",
            },
            Id: {
                type: Number,
                default: 0,
            },
        },
        components: {
            "loading": loading
        },
        emits: ["save", "close"],
        setup(props, context) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");
            const api = ref(process.env.VUE_APP_API);
            const contact = ref({});
            const sending = ref(false);
            const options = ref({ states: [] });
            const quote = ref({});

            const submitAttempted = ref(false);

            const loading = ref(true);
            const name = ref("");

            const GetQuote = async () => {
                var response = await $http.get("/api/quote/" + props.Id);
                quote.value = response.data;
            };

            const SendQuote = async () => {
                submitAttempted.value = true;
                if (allIsValid.value) {
                    sending.value = true;
                    try {
                        await $http.post("/api/quote/send", quote.value);
                        $success("Quote sent.");
                    } catch (e) {
                        $error("Failed to send quote.");
                    }
                    sending.value = false;
                    context.emit("close");
                }
            };

            const GetContactInfo = async () => {
                try {
                    var response = await $http.get("/api/user");
                    global.$store.dispatch("setContact", {
                        Email: response.data.UserName,
                        FirstName: response.data.Profile.FirstName,
                        LastName: response.data.Profile.LastName,
                        Company: response.data.Profile.Company,
                        Phone: response.data.Profile.Phone,
                        Fax: response.data.Profile.Fax,
                    });
                } catch (err) {
                    return Promise.reject("Failed to get contact info");
                }
            };

            const v = useValidate(
                {
                    quote: {
                        Shipping: {
                            required,
                            numeric
                        },
                        Grilles: {
                            $each: helpers.forEach({
                                GrilleSizes: {
                                    $each: helpers.forEach({
                                        Price: {
                                            required,
                                            numeric
                                        }
                                    })
                                }
                            })
                        }
                    }
                }, {
                quote
            }
            );

            const shippingIsError = computed(() => { return v.value.quote.Shipping.$invalid });
            const sizesIsError = computed(() => {
                return !v.value.quote.Grilles.$each.$response.$valid;
            });
            const allIsValid = computed(() => {
                return !(shippingIsError.value || sizesIsError.value);
            });

            onMounted(async () => {
                if (
                    global.$store.getters.isLoggedIn &&
                    Object.keys(global.$store.getters.contact).length === 0
                ) {
                    await GetContactInfo();
                }

                await GetQuote();

                contact.value = global.$store.getters.contact;
                loading.value = false;
            });

            const GetStates = async () => {
                var response = await $http.get("/api/state");
                options.value.states = response.data;
                return response;
            };

            const Cancel = () => {
                context.emit("close");
            };

            const qtySum = (grille) => {
                let sum = 0;
                for (let i = 0; i < grille.GrilleSizes.length; i++) {
                    sum += grille.GrilleSizes[i].Quantity;
                }
                return sum;
            };

            return {
                v,
                shippingIsError,
                sizesIsError,
                submitAttempted,
                allIsValid,
                loading,
                options,
                name,
                sending,
                quote,
                qtySum,
                Cancel,
                SendQuote,
            };
        },
    };
</script>
<style lang="scss">
    .limit-modal-size {
        min-width: 50vw;
        min-height: 80vh;
    }

    .loading-wrap {
        display: inline-block;
        position: absolute;
        bottom: 1rem;
        right: 2rem;
        font-size: 0.8em;
    }
</style>
