<template>
	<div class="modal-card limit-modal-size" style="width: auto">
		<header class="modal-card-head">
			<p class="modal-card-title">
				{{ title }}
			</p>
			<o-icon type="button" icon="times" @click="$emit('close')" clickable></o-icon>
		</header>
		<section class="modal-card-body">
			<o-steps
				v-model="step"
				position="centered"
				size="small"
				:hasNavigation="false"
				class="nav-hidden">
				<o-step-item :step="1" label="Contact" icon="user">
					<div v-if="loading">
						<loading icon="spinner-third" spin :pulse="false"></loading>
					</div>
					<div class="columns is-multiline" v-else>
						<div class="column is-6">
							<o-field label="First name">
								<o-input v-model="contact.FirstName"></o-input>
							</o-field>
							<o-field label="Last name">
								<o-input v-model="contact.LastName"></o-input>
							</o-field>
							<o-field label="Email">
								<o-input v-model="contact.Email"></o-input>
							</o-field>

							<o-field label="Address">
								<o-input v-model="contact.Address"></o-input>
							</o-field>
							<o-field label="City">
								<o-input v-model="contact.City"></o-input>
							</o-field>
							<o-field label="State">
								<o-select v-model="contact.StateId" placeholder="Select a State" expanded>
									<option v-for="state in options.states" :value="state.Id" :key="state.Id">
										{{ state.Name }}
									</option>
								</o-select>
							</o-field>
							<o-field label="Zip">
								<o-input v-model="contact.Zip"></o-input>
							</o-field>
						</div>
						<div class="column is-6">
							<o-field label="Company">
								<o-input v-model="contact.Company"></o-input>
							</o-field>
							<o-field label="Job name">
								<o-input v-model="contact.Job"></o-input>
							</o-field>
							<o-field label="Reference">
								<o-input v-model="contact.Reference"></o-input>
							</o-field>
							<o-field label="Phone">
								<o-input v-model="contact.Phone"></o-input>
							</o-field>
							<o-field label="Fax">
								<o-input v-model="contact.Fax"></o-input>
							</o-field>
							<o-field label="Architect">
								<o-input v-model="contact.Architect"></o-input>
								<template v-slot:message>
									<a v-if="!$store.getters.isLoggedIn && !register" @click="LoadRegistration">
										Sign up to save schedules!
									</a>
								</template>
							</o-field>
							<o-field label="Password" v-if="register">
								<o-input type="password" v-model="contact.Password"></o-input>
								<template v-slot:message>
									<span class="is-danger" v-if="contact.Password !== contact.ConfirmPassword">
										Passwords do not match.
									</span>
								</template>
							</o-field>
							<o-field label="Confirm Password" v-if="register">
								<o-input type="password" v-model="contact.ConfirmPassword"></o-input>
							</o-field>
						</div>
					</div>
				</o-step-item>
				<o-step-item :step="2" label="Sign In" icon="user-unlock">
					<o-button icon-left="arrow-left" size="small" class="is-text" @click="step = 1">
						Back
					</o-button>
					<h2 class="is-title is-4 mb-2">Login</h2>
					<div class="my-4 box">
						<o-field label="Email">
							<o-input v-model="loginForm.username"></o-input>
						</o-field>
						<o-field label="Password">
							<o-input type="password" v-model="loginForm.password"></o-input>
						</o-field>
						<a href="register" target="_blank">Create an account</a>
					</div>
				</o-step-item>
			</o-steps>
		</section>
		<footer class="modal-card-foot is-justify-content-space-between">
			<div>
				<o-button type="button" variant="light" @click="Cancel">Cancel</o-button>
				<o-button
					type="button"
					variant="primary"
					:class="{ 'is-loading': saving }"
					:disabled="!IsValid || saving"
					@click="Send"
					v-if="step <= 1">
					Send
				</o-button>
				<o-button
					type="button"
					variant="primary"
					:disabled="saving"
					@click="TryLogin"
					v-if="step == 2">
					Login
				</o-button>
			</div>
			<div>
				<o-button
					type="button"
					variant="light"
					@click="StepSignUp()"
					v-if="!$store.getters.isLoggedIn && step <= 1">
					Already a member?
				</o-button>
			</div>
		</footer>
	</div>
</template>
<script>
	import { email } from "@/services/validation";
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";

	export default {
		props: {
			title: {
				type: String,
				default: "Contact Information"
			},
			description: {
				type: String,
				default: ""
			}
		},
		emits: ["save", "close"],
		setup(props, context) {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $http = inject("$http");
			const $success = inject("$success");
			const $error = inject("$error");
			const api = ref(process.env.VUE_APP_API);
			const contact = ref({});
			const sending = ref(false);
			const saving = ref(false);
			const options = ref({ states: [] });

			const loading = ref(true);
			const name = ref("");
			const step = ref(0);
			const register = ref(false);
			const loginForm = ref({});
			const IsValid = computed(() => {
				const emailAddress = (contact.value.Email || "").toLowerCase();

				return (
					(register.value &&
						contact.value.Password &&
						contact.value.Password === contact.value.ConfirmPassword &&
						contact.value.StateId >= 0 &&
						contact.value.Address &&
						email(emailAddress)) ||
					(!register.value &&
						email(emailAddress) &&
						contact.value.Address &&
						contact.value.City &&
						contact.value.Zip &&
						contact.value.StateId >= 0)
				);
			});
			const Contact = computed(() => {
				const contactInfo = { ...contact.value };
				delete contactInfo.Password;
				delete contactInfo.ConfirmPassword;

				return contactInfo;
			});

			const GetContactInfo = async () => {
				try {
					var response = await $http.get("/api/user");
					global.$store.dispatch("setContact", {
						Email: response.data.UserName,
						FirstName: response.data.Profile.FirstName,
						LastName: response.data.Profile.LastName,
						Company: response.data.Profile.Company,
						Phone: response.data.Profile.Phone,
						Fax: response.data.Profile.Fax
					});
				} catch (err) {
					return Promise.reject("Failed to get contact info");
				}
			};

			onMounted(async () => {
				await GetStates();

				if (
					global.$store.getters.isLoggedIn &&
					Object.keys(global.$store.getters.contact).length === 0
				) {
					await GetContactInfo();
				}

				contact.value = global.$store.getters.contact;
				loading.value = false;
			});

			const GetStates = async () => {
				var response = await $http.get("/api/state");
				options.value.states = response.data;
				return response;
			};
			const LoadRegistration = async () => {
				register.value = true;
				await GetStates();
			};

			const StepSignUp = () => {
				register.value = false;
				contact.value = Contact.value; //Removes register details
				step.value = 2;
			};

			const TryLogin = async () => {
				saving.value = true;
				try {
					var response = await $http.post("/api/user/login", loginForm.value);
					global.$store.dispatch("login", response.data);
					await GetContactInfo();
					contact.value = global.$store.getters.contact;
					step.value = 1;
				} catch (err) {
					$error("Failed to login.");
				}
				saving.value = false;
			};

			const TryRegister = async () => {
				try {
					var response = await $http.post("/api/user/register", {
						username: contact.value.Email,
						password: contact.value.Password,
						company: contact.value.Company,
						phone: contact.value.Phone,
						fax: contact.value.Fax,
						firstname: contact.value.FirstName,
						lastname: contact.value.LastName,
						address: contact.value.Address,
						zip: contact.value.Zip,
						city: contact.value.City,
						stateId: contact.value.StateId
					});
					global.$store.dispatch("login", response.data);
					return true;
				} catch (err) {
					return false;
				}
			};

			const Cancel = () => {
				context.emit("close");
			};
			const Send = async () => {
				saving.value = true;
				global.$store.dispatch("setContact", Contact.value);

				if (register.value) {
					const result = await TryRegister();
					if (!result) {
						saving.value = false;
						return;
					}
				}

				const close = () => {
					context.emit("close");
				};
				context.emit("send", {
					grilles: props.grilles,
					contact: Contact.value,
					loading: sending,
					close: close
				});
			};

			return {
				loading,
				saving,
				options,
				name,
				step,
				register,
				loginForm,
				contact,
				IsValid,
				LoadRegistration,
				StepSignUp,
				TryLogin,
				Cancel,
				Send
			};
		}
	};
</script>
<style lang="scss">
	.limit-modal-size {
		min-width: 50vw;
		min-height: 80vh;
	}
</style>
