<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Schedule
            </p>
            <o-icon type="button" icon="times" @click="$emit('close')" clickable></o-icon>
        </header>
        <section class="modal-card-body">
            <div class="columns">
                <div class="column is-12">
                    <o-table :mobile-cards="false" :data="grilles">
                        <o-table-column field="GrilleStyle.Name" label="Grille" sortable v-slot="props">
                            <img :src="api + 'api/image/' + props.row?.GrilleStyle?.Src" :alt="props.row?.GrilleStyle?.Name" style="max-width: 4em; margin: auto" />
                        </o-table-column>
                        <o-table-column field="Type.Name" label="Description" sortable>
                            <template v-slot="props">
                                <o-collapse class="expando-table" animation="slide" :open="false">
                                    <template v-slot:trigger="p" class="card-header" role="button">
                                        <p class="expando-table-header-title is-flex" style="justify-content:space-between">
                                            <span>
                                                {{props.row.GrilleStyle.Name}}
                                                <span v-show="props.row?.GrilleFrame?.Id"> - {{props.row?.GrilleFrame?.Name}}</span>
                                            </span>
                                            <span>
                                                <a class="expando-table-header-icon">
                                                    <o-icon :icon="p.open ? 'caret-up' : 'caret-down'"> </o-icon>
                                                </a>
                                            </span>
                                        </p>
                                    </template>
                                    <div class="expando-table-content">
                                        <div class="content">
                                            <o-field label="Type">
                                                {{props.row.Type.Name}}
                                            </o-field>
                                            <o-field label="Sizes" grouped>
                                                <table class="simple-table">
                                                    <tr>
                                                        <th>Measurement</th>
                                                        <th>Dimensions</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                    <tr v-for="size in props.row?.GrilleSizes" :key="size">
                                                        <td>{{size.MeasurementTypeId == 1 ? "Opening:" : "Overall:"}}</td>
                                                        <td>{{size.Height}}&quot;H&nbsp;&#215;&nbsp;{{size.Length}}&quot;L</td>
                                                        <td>
                                                            <o-input class="show-input-arrows" v-model="size.Quantity" type="number" min="0" step="1"></o-input>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </o-field>
                                            <o-field v-if="props.row?.GrilleThickness.Name" label="Thickness">
                                                {{props.row.GrilleThickness.Name}}
                                            </o-field>

                                            <o-field label="Style">
                                                {{props.row.GrilleStyle.Name}}
                                            </o-field>
                                            <o-field label="Frame" v-if="props.row?.GrilleFrame">
                                                {{props.row?.GrilleFrame?.Name}}
                                            </o-field>
                                            <o-field label="Material">
                                                {{props.row.GrilleMaterial.Name}}
                                            </o-field>
                                            <o-field label="Finish">
                                                {{props.row.GrilleFinish.Name}}
                                            </o-field>
                                            <div v-if="props.row?.GrilleOption">
                                                <span v-show="props.row.GrilleOption?.Application">{{props.row.GrilleOption?.Application}}<br /></span>
                                                <span v-show="props.row.GrilleOption?.HasMountingHoles">w/ Mounting Holes<br /></span>
                                                <span v-show="props.row.GrilleOption?.IsFloorApplication">Floor<br /></span>
                                                <span v-show="props.row.GrilleOption?.HasRemovableCore">Removable Core</span>
                                            </div>
                                        </div>
                                    </div>
                                </o-collapse>
                            </template>
                        </o-table-column>
                        <o-table-column field="Quantity" label="Quantity" sortable numeric v-slot="props">
                            {{TotalAmount(props.row)}}&nbsp;(Total)
                        </o-table-column>
                        <o-table-column numeric style="justify-content: flex-end !important;" v-slot="props">
                            <o-button icon-left="edit" size="small" class="mx-1" @click="Edit(props.row)"></o-button>
                            <o-button icon-left="copy" size="small" class="mx-1" @click="Copy(props.row)"></o-button>
                            <o-button icon-left="trash" variant="danger" size="small" class="mx-1" @click="Delete(props.row)"></o-button>
                        </o-table-column>
                    </o-table>
                </div>
            </div>
            <div class="columns">
                <div class="column is-12">
                    <p>
                        <b>Contact Information</b>
                    </p>
                </div>
            </div>
            <div class="columns">
                <div class="column is-6">
                    <o-field label="First name">
                        <o-input v-model="contact.FirstName"></o-input>
                    </o-field>
                    <o-field label="Last name">
                        <o-input v-model="contact.LastName"></o-input>
                    </o-field>
                    <o-field label="Email">
                        <o-input v-model="contact.Email"></o-input>
                    </o-field>
                </div>
                <div class="column is-6">
                    <o-field label="Company">
                        <o-input v-model="contact.Company"></o-input>
                    </o-field>
                    <o-field label="Phone">
                        <phone-number v-model="contact.Phone"></phone-number>
                    </o-field>
                    <o-field label="Fax">
                        <o-input v-model="contact.Fax"></o-input>
                    </o-field>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <o-button type="button" variant="primary" @click="Send" :disabled="!isValid || sending">Request a Quote</o-button>
            <o-button type="button" variant="warning" @click="Save" :disabled="saving" v-if="$store.getters.isLoggedIn">Save Schedule</o-button>
            <o-button type="button" variant="light" @click="Close">Close</o-button>
        </footer>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    import { Name } from "@/components/modal";
    import { PhoneNumber } from "@/components/input";
    export default {
        components: { PhoneNumber },
        props: {
            grilles: {
                type: Array,
                default: () => { return []; }
            }
        },
        emit: ["close", "send", "save", "edit", "copy", "delete"],
        setup(props, context) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const api = ref(process.env.VUE_APP_API);
            const contact = ref({});
            const sending = ref(false);
            const saving = ref(false);

            const sizes = computed(() => { return props.row?.GrilleSizes; });

            onMounted(() => {
                contact.value = global.$store.getters.contact;
            });

            const Close = () => {
                context.emit("close");
            };
            const Send = () => {
                const close = () => { context.emit("close"); };
                context.emit("send", { grilles: props.grilles, contact: contact.value, loading: sending, close: close });
            };
            const Save = () => {
                global.$oruga.modal.open({
                    parent: this,
                    component: Name,
                    trapFocus: true,
                    active: true,
                    props: {
                        title: "Name Grille Schedule",
                        description: "Set a name to easily find this again."
                    },
                    events: {
                        save: (result) => {
                            const close = () => { context.emit("close"); };
                            context.emit("save", { name: result.name, grilles: props.grilles, loading: saving, close: close });
                            result.close();
                        }
                    }
                });
            };
            const Edit = (row) => {
                context.emit("edit", row);
                context.emit("close");
            };
            const Copy = (row) => {
                context.emit("copy", row);
                context.emit("close");
            };
            const Delete = (row) => {
                context.emit("delete", row);
            };

            const isValid = computed(() => {
                return contact.value.FirstName && contact.value.LastName && contact.value.Email && contact.value.Company && contact.value.Phone;
            });

            const lastSize = computed(() => {
                const last = props.row?.GrilleSizes[props.row?.GrilleSizes.length - 1];
                return last;
            });

            const TotalAmount = (row) => {
                let total = 0;
                for (const size of row.GrilleSizes) {
                    if (size.Quantity) {
                        total += parseInt(size.Quantity);
                    }
                }
                return total;
            };

            return {
                api,
                contact,
                sending,
                saving,
                isValid,
                Close,
                Send,
                Save,
                Edit,
                Copy,
                Delete,
                sizes,
                lastSize,
                TotalAmount
            };
        }
    }
</script>
<style lang="scss">
    .simple-table {
        border: 1px solid #e1e1e1;
        border-collapse: separate;
        border-radius: 0.5em;

        & th {
            font-size: 0.75em;
            border-bottom-width: 1px !important;
            background-color: #f5f5f5;

            &:first-child {
                border-radius: 0.6em 0 0 0;
            }

            &:last-child {
                border-radius: 0 0.6em 0 0;
            }
        }

        & td {
            border-bottom-width: 1px !important;

            &:first-child {
                border-left-width: 0 !important;
            }
        }

        & tr:last-child td {
            border-bottom-color: inherit;
            border-bottom-width: 0 !important;
        }

        & td:last-child, & th:last-child {
            border-left-width: 1px !important;
        }

        & input {
            height: 1.5em;
            width: 2.5em;
            padding: 0 0 0 0.3em;
        }
    }
</style>
