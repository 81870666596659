<template v-if="!isDisabled">
	<div class="inputWrap">
		<label>Dimensions</label>
		<o-select v-model="dimensions" :class="dimensionsIsError ? 'inputError' : ''">
			<option v-for="option in stockSizes" :key="option" :value="option">
				<span>{{ option.Height }}&quot;</span>
				&nbsp;&times;&nbsp;
				<span>{{ option.Length }}&quot;</span>
			</option>
		</o-select>
	</div>
	<div class="inputWrap sbQuantity">
		<label>Qty.</label>
		<o-input
			v-model.number="quantity"
			type="number"
			min="1"
			:class="qtyIsError ? 'inputError' : ''"></o-input>
	</div>
</template>
<script>
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	export default {
		props: {
			modelValue: {
				type: Object,
				default: () => {
					return {};
				}
			},
			disabled: {
				type: Boolean,
				default: false
			},
			grille: {
				type: [Array, Object],
				default: () => {
					return null;
				}
			},
			dimensionsIsError: {
				type: Boolean,
				default: false
			},
			qtyIsError: {
				type: Boolean,
				default: false
			}
		},
		emits: ["update:modelValue", "disabled", "input"],
		setup(props, context) {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $httpGetCached = inject("$httpGetCached");
			const $error = inject("$error");

			const api = ref(process.env.VUE_APP_API);
			const options = ref([]);
			const loading = ref(false);

			const sizeModel = ref(props.modelValue);

			const dimensions = ref({});
			const quantity = ref(0);

			const stockSizes = ref([]);
			const GetStockSizes = async () => {
				if (props.grille.GrilleStyle.Id) {
					loading.value = true;
					try {
						var response = await $httpGetCached(
							"/api/grilleStyleType/" + props.grille.GrilleStyle.Id + "/stock/sizes"
						);
						stockSizes.value = response.data;
						for (const size of stockSizes.value) {
							size.MeasurementTypeId = 1;
						}
					} catch (err) {
						$error("Failed to get stock sizes.");
					}
					loading.value = false;
				}
			};

			const UpdateModel = () => {
				const model = {
					Height: dimensions.value.Height,
					Length: dimensions.value.Length,
					Quantity: quantity.value,
					MeasurementTypeId: sizeModel.value.MeasurementTypeId || 1,
					VariantId: dimensions.value.VariantId
				};
				context.emit("update:modelValue", model);
				context.emit("input");
			};

			const UpdateSelection = () => {
				if (props.modelValue) {
					const searchResult = stockSizes.value.find((x) => {
						return x.Height === sizeModel.value.Height && x.Length === sizeModel.value.Length;
					});

					if (searchResult) {
						dimensions.value = searchResult;
					}

					quantity.value = sizeModel.value.Quantity;
				}
			};

			watch(
				() => dimensions.value,
				(n, o) => {
					UpdateModel();
				},
				{ deep: true }
			);
			watch(
				() => quantity.value,
				(n, o) => {
					UpdateModel();
				}
			);

			watch(
				() => props.grille.GrilleStyle.Id,
				(n, o) => {
					GetStockSizes();
					UpdateSelection();
				}
			);

			onMounted(async () => {
				await GetStockSizes();
				UpdateSelection();
			});

			return {
				api,
				options,
				dimensions,
				quantity,
				stockSizes,
				UpdateModel
			};
		}
	};
</script>
<style lang="scss">
	@import "@/assets/css/globalVars.scss";

	.autocompleteError {
		@include o-input-error;
	}
</style>
