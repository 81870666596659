<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                {{title}}
            </p>
            <o-icon type="button" icon="times" @click="$emit('close')" clickable></o-icon>
        </header>
        <section class="modal-card-body">
            <div class="columns is-vcentered">
                <div class="column is-12">
                    <o-field label="Name">
                        <o-input v-model="name" placeholder="Enter a name"></o-input>
                    </o-field>
                    <p class="help">
                        {{description}}
                    </p>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <o-button type="button" variant="light" @click="Cancel">Cancel</o-button>
            <o-button type="button" variant="primary" @click="Save">Save</o-button>
        </footer>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        props: {
            title: {
                type: String,
                default: "Create a Name"
            },
            description: {
                type: String,
                default: ""
            }
        },
        emits: ["save", "close"],
        setup(props, context) {

            const name = ref('');

            const Cancel = () => {
                context.emit("close");
            };
            const Save = () => {
                const close = () => { context.emit("close"); };
                context.emit("save", { name: name.value, close: close });
            }

            return {
                name,
                Cancel,
                Save
            };
        }
    }
</script>