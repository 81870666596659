<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                {{title}}
            </p>
            <o-icon type="button" icon="times" @click="$emit('close')" clickable></o-icon>
        </header>
        <section class="modal-card-body">
            <image-selector v-model="selection" :data="options" :size="size" :multi="multi"></image-selector>
        </section>
        <footer class="modal-card-foot">
            <o-button type="button" variant="light" @click="Cancel">Cancel</o-button>
            <o-button type="button" variant="primary" @click="Select">Select</o-button>
        </footer>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    import ImageSelector from "@/components/imageSelector.vue";
    export default {
        components: {
            "image-selector": ImageSelector
        },
        props: {
            data: {
                type: Array,
                default: () => { return []; }
            },
            size: {
                type: [String, Number],
                default: 8
            },
            title: {
                type: String,
                default: "Select an Option"
            },
            multi: {
                type: Boolean,
                default: false
            }
        },
        emits: ["close", "selected"],
        setup(props, context) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const options = ref(props.data);
            const selection = ref([]);

            const UnselectAll = () => {
                options.value = options.value.map(x => {
                    x.selected = false;
                    return x;
                });
            };

            const Select = () => {
                const items = selection.value;
                context.emit("selected", items);
                context.emit("close");
            }

            const Cancel = () => {
                context.emit("close");
            };

            onMounted(() => {
                UnselectAll();
            });

            return {
                options,
                selection,
                Select,
                Cancel
            };
        }
    }

</script>