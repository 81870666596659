<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Add {{type}} Type
            </p>
            <o-icon type="button" icon="times" @click="$emit('close')" clickable></o-icon>
        </header>
        <section class="modal-card-body">
            <div class="columns is-vcentered">
                <div class="column is-6">
                    <o-field label="Name">
                        <o-input v-model="entity.Name"></o-input>
                    </o-field>
                </div>
                <div class="column is-6">
                    <div class="has-text-centered">
                        <o-upload v-model="entity.image" drag-drop>
                            <section class="has-text-centered">
                                <p>
                                    <o-icon icon="upload" size="is-large"></o-icon>
                                </p>
                                <p>Drop your file here or click to upload</p>
                            </section>
                        </o-upload>
                    </div>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <o-button type="button" variant="light" @click="Cancel">Cancel</o-button>
            <o-button type="button" variant="primary" @click="Save">Save</o-button>
        </footer>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        props: {
            type: {
                type: String,
                default: ""
            }
        },
        setup(props, context) {
            const entity = ref({});
            const Cancel = () => {
                context.emit("close");
            };
            const Save = () => {
                const close = () => { context.emit("close"); };
                context.emit("save", { entity: entity.value, close: close });
            }

            return {
                entity,
                Cancel,
                Save
            };
        }
    }
</script>