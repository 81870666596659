<template v-if="!isDisabled">
	<div class="inputWrap">
		<label :class="isDisabled ? 'disabled' : ''">{{Capitalize(type)}}</label>
		<label :class="isError ? 'errorLabel' : 'errorLabel labelDisabled'"><slot name="error">Required</slot></label>
        <o-autocomplete v-if="!isMobile" open-on-focus
						field="Name"
						menuPosition="bottom"
						:disabled="isDisabled"
						:data="filteredOptions"
                        :class="{ 'autocompleteError': isError }"
						v-model="name"
						@focus="OnFocus"
						@input="OnInput"
						@select="OnInput"
						@blur="OnBlur">
            <template v-slot="scope">
                <div class="flex-column">
                    <div class="media">
                        <div class="media-left" v-if="showImage">
                            <fast-tooltip :src="scope.option.Src" :maxWidth="'12em'" class="ftt_overlay" position="left">
                                <o-icon icon="search-plus" class="mx-2 my-2" pack="fas"></o-icon>
                            </fast-tooltip>
                            <img :src="`${api + 'api/image/' + scope.option.Src || 0}`" />
                        </div>
                        <div class="media-content">
                            <p>{{ scope.option.Name }}</p>
                            <fast-tooltip v-if="type==='frame'" :listSrc="'api/grilleFrameType/application/' + scope.option.Id" class="ftt_oruga" position="right">
                                <o-icon icon="question-circle" class="mx-2 my-2"></o-icon>
                            </fast-tooltip>
                        </div>
                    </div>
                    <i v-if="scope.option.Name.includes('®')" class="patent-text">US Patent No’s #9,765,988 #10,871,305</i>
                </div>
            </template>
		</o-autocomplete>
        <o-dropdown v-else open-on-focus
                    menuPosition="bottom"
                    :disabled="isDisabled"
                    :class="{ 'autocompleteError': isError, 'select': true }"
                    v-model="name"
                    @focus="OnFocus"
                    @input="OnMobileInput"
					@change="OnMobileInput">
            <template #trigger>
                <o-button class="input">
                    <span>{{name}}</span>
                </o-button>
            </template>
            <o-dropdown-item v-for="option in options" :key="option" :value="option.Name">
                <template #default>
                    <div class="media">
                        <div class="media-left" v-if="showImage">
                            <img :src="`${api + 'api/image/' + option.Src || 0}`" />
                        </div>
                        <div class="media-content">
                            {{ option.Name }}
                        </div>
                    </div>
                </template>
            </o-dropdown-item>
        </o-dropdown>
	</div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    import { fastTooltip } from "@/components";
    export default {
        components: {
            "fast-tooltip": fastTooltip
        },
		props: {
			modelValue: {
				type: [Array, Object],
				default: () => { return null; }
			},
			type: {
				type: String,
				default: ""
			},
			limitingIds: {
				type: Object,
				default: () => { return null; }
			},
			disabled: {
				type: Boolean,
				default: false
			},
			isError: {
				type: Boolean,
				default: false
            },
            mobile: {
                type: Boolean,
                default: false
			}
		},
		emits: ["update:modelValue", "disabled", "sbInput", "blur"],
		setup(props, context) {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $httpGetCached = inject("$httpGetCached");
			const $moment = inject("$moment");
			const $success = inject("$success");
			const $error = inject("$error");

			const api = ref(process.env.VUE_APP_API);
			const options = ref([]);
			const name = ref("");
			const src = ref("");
            const showAll = ref(false);

			const isDisabled = computed(() => {
				return props.disabled || src.value === "" || options.value.length === 0;
			});

			const isMobile = computed(() => {
				return props.mobile;
            });

			const showImage = computed(() => {
				const typeStr = props.type.toLowerCase();
				return typeStr !== "thickness";
			});

			const filteredOptions = computed(() => {
				const results = options.value.filter(option => {
					return option.Name?.toLowerCase().indexOf(name.value?.toLowerCase()) >= 0;
				});
				if (showAll.value || results.length === 0) {
					return options.value;
				} else {
					return results;
				}
			});

			const OnFocus = () => {
				showAll.value = true;
			};

			const OnInput = (e) => {
				showAll.value = false;
				context.emit("sbInput", e);
			};

			const OnMobileInput = (e) => {
				const option = options.value.find((o)=>{
					return o.Name === e;
				});
				if (option) {
					showAll.value = false;
					context.emit("sbInput", option);
				}
			};

			const Capitalize = (s) => {
				s = s.toLowerCase();
				return s.charAt(0).toUpperCase() + s.slice(1);
			}

			const dependencyList = {
				application: [],
                style: ["application"],
				pattern: ["style"],
				material: ["style"],
				frame: ["type", "application"],
				finish: ["material"],
				thickness: ["type", "material"],
			};

			const UpdateOptions = async () => {
				const type = props.type.toLowerCase();
				if (type === "type") {
					src.value = "/api/grilleType";
				} else if (type === "frame") {
                    if (props.limitingIds.type === 1) {
                        src.value = "/api/applicationType/" + props.limitingIds.application + "/frames";
					} else {
						src.value = "";
					}
				} else if (type === "style") {
					src.value = "/api/grilleStyleType";
				} else if (type === "pattern") {
					src.value = "/api/grilleStyleType/" + props.limitingIds.style + "/patterns/details";
				} else if (type === "material") {
					if (props.limitingIds.style > 0) {
						src.value = "/api/grilleStyleType/" + props.limitingIds.style + "/material";
					} else {
						src.value = "";
					}
				} else if (type === "finish") {
					if (props.limitingIds.material > 0) {
						src.value = "/api/grilleMaterialType/" + props.limitingIds.material + "/finishes";
					} else {
						src.value = "";
					}
				} else if (type === "thickness") {
					if (props.limitingIds.material >= 0 && props.limitingIds.type === 2) {
						src.value = "/api/grilleMaterialType/" + props.limitingIds.material + "/thickness";
					} else {
						src.value = "";
					}
                } else if (type === "application") {
                    src.value = "/api/applicationType/";
                }
				try {
					if (src.value !== "") {
						let response = await $httpGetCached(src.value);
                        options.value = response.data;

                        if (props.type.toLowerCase() === "style") {
                            if ([1, 4, 5, 6, 7, 8].find(x => x === props.limitingIds.application)) {
                                options.value = options.value.filter(x => x.GrilleTypeId != 5);
                            }
                        }

                        if (!InOptions(name.value)) {
                            name.value = "";
                        }
					} else {
						options.value = [];
						name.value = "";
					}
				}
				catch (err) {
					console.error(err);
				}

				context.emit("disabled", options.value.length === 0);
			}

			UpdateOptions();

			const InOptions = (s) => {
				if (options.value.length === 0) return false;
				return options.value.find(o => o.Name && s?.toLowerCase() === o.Name.toLowerCase());
			};

			const UpdateModel = () => {
                const matches = options.value.filter(option => option.Name.toLowerCase() === name.value.toLowerCase());
				const model = matches.length > 0 ? matches[0] : {};
				context.emit("update:modelValue", model);
			};

			const UpdateSelection = () => {
				if (props.modelValue) {
					name.value = props.modelValue.Name;
				}
			};

			const OnBlur = () => {
				context.emit("blur");
			};

			watch(() => name.value, (n, o) => {
				if (InOptions(n) || n === "") {
					UpdateModel();
				}
			});

			if (props.limitingIds) {
				const dependencies = dependencyList[props.type];
				for (const key of dependencies) {
					watch(() => props.limitingIds[key], (n, o) => {
						if (n && n !== o) {
							UpdateOptions();
							UpdateModel();
						}
					});
				}
			}

			onMounted(() => {
				UpdateSelection();
			});

			return {
				api,
				options,
				name,
                filteredOptions,
				isDisabled,
				isMobile,
				showImage,
				OnFocus,
				OnInput,
				OnMobileInput,
                Capitalize,
				UpdateModel,
				OnBlur
			};
		}
	}

</script>
<style lang="scss">
	@import "@/assets/css/globalVars.scss";

    body > div:not(#app):not(.modal) {
        pointer-events: none;
        z-index: 400 !important;

        & .tooltip-content.is-info:not(.is-right) {
            border-radius: 0;
            padding: 2px;
            box-shadow: none;
            line-height: 0;
        }
    }

    .flex-column {
        display: flex;
        flex-direction: column;
    }

    .media-left {
        position: relative;
        line-height: 0;

        & img {
            box-shadow: 0 0.05em 0.2em 0 rgb(0 0 0 / 40%);
            width: 4rem;

            &:hover {
                //outline: 2px solid $info;
            }
        }
    }

    .media-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        font-size: 1em;
        gap: 0.1em;
        text-align: left;
        white-space: break-spaces;
    }

    .inputWrap {
        & label.disabled {
            background-color: transparent;
            color: transparent;
        }

        & .dropdown-content.dropdown-menu {
            z-index: 300 !important;
        }

        & .button-wrapper > span {
            display: flex;
            justify-content: space-between;
        }

        & .dropdown, & .dropdown-trigger, & .button-wrapper {
            width: 100%;
        }

        & .o-drop__menu {
            z-index: 3000;
        }

        & .media-left .b-tooltip {
            position: relative !important;
            top: 0 !important;
            right: 0 !important;
            bottom: unset !important;
            left: unset !important;
        }

        & button {
            background-color: white;
            color: #363636;

            &:hover {
                background-color: white;
            }
        }

        & .dropdown.is-mobile-modal img {
            width: 8rem;
        }
    }

    .fastTooltipControl.ftt_overlay {
        position: absolute;
        bottom: 0.2em;
        right: 0.2em;
        padding: 0.1em;
        background-color: $info;
        //border: 1px solid white;
        //border-width: 0 0 1px 1px;
        box-shadow: -1px 1px 2px rgb(0 0 0 / 0.65);

        .icon {
            color: $white;
        }

        & path {
            //filter: drop-shadow(1.2em 1.2em 2em black);
            //stroke: $info;
            //stroke-width: 15;
        }
    }

    .dropdown-item .media-content .fastTooltipControl {
        position: relative;
        top: 0;
        left: 0;
        background-color: transparent;
    }

	.autocompleteError {
		@include o-input-error;
	}
</style>
<style lang="scss" scoped>
    .patent-text {
        max-width: unset !important;
        align-self: center;
        font-size: 0.6em;
        text-align: center;
    }
</style>
